import marquee from "vanilla-marquee";
import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';

window.addEventListener('load', function () {

  if( document.body.classList.contains('page-template-page-practice')) {

  	// MARQUEE

  	new marquee(document.querySelector('.marquee'), {
  		duplicated:true,
  		speed: 20,
  		delayBeforeStart:0,
  		startVisible: true,
  		gap:0,
  		recalcResize:true
  	});

    // IMAGES SLIDER

  	const images = document.querySelector('.practice-facilities__slider');
    const imagesSwiper = new Swiper(images, {
      modules: [EffectFade, Navigation, Pagination],
      slidesPerView: 1,
      speed:1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
     	navigation: {
        nextEl: ".slider-next",
        prevEl: ".slider-prev",
      },
     	pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
    });

  }

}, false);