window.addEventListener('load', function () {  

	if( document.querySelector('.accordion')) {

    const accordions = document.querySelectorAll('.accordion');
    
    accordions.forEach((accordion) => {
    	
    	const tabs = accordion.querySelectorAll('.accordion-tab');

    	tabs.forEach((tab) => {
    		tab.addEventListener('click', function(){
          // TOGGLE TAB
    			this.classList.toggle("active");
          this.ariaExpanded = !this.ariaExpanded;
          // TOGGLE PANEL
          this.nextElementSibling.classList.toggle("active");
          this.nextElementSibling.hidden = !this.nextElementSibling.hidden;
    		})
    	})

    })

  }

}, false);