window.addEventListener('load', function () {

  if( document.body.classList.contains('home')) {

    if (window.matchMedia("(min-width: 768px)").matches) {
      
      const buttons = document.querySelectorAll('.home-services__tabs .tab');
      const images = document.querySelectorAll('.home-services__tabs .images figure');

      const handleBtnClick = (e) => {
        const target = e.currentTarget;
        const index = target.dataset.index;
        buttons.forEach((button) => {
          button.classList.remove('active');
          button.nextElementSibling.classList.remove('active');
        })
        images.forEach((image) => {
          if(image.dataset.index == index){
            image.classList.add('active');
          }else{
            image.classList.remove('active');
          }
        })
        target.classList.add('active');
        target.nextElementSibling.classList.add('active');
      }

      buttons.forEach((button) => {
        button.addEventListener('click', handleBtnClick);
      })

    }

  }

}, false);