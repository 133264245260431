import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const body = document.querySelector('body');

window.addEventListener('load', function () {

	const navToggle = document.getElementById('nav-toggle');
  const header = document.getElementById('#site-header');

  // MOBILE NAV

  const handleNavToggleClick = () => {
    body.classList.toggle('nav-active');
    if(navToggle.getAttribute('aria-expanded') === 'true'){
      navToggle.setAttribute('aria-expanded', 'false');
    }else{
      navToggle.setAttribute('aria-expanded', 'true');
    }
  }

  navToggle.addEventListener('click', handleNavToggleClick);


  const disclaimer = document.querySelector('.disclaimer');
  ScrollTrigger.create({
    start:'top top',
    onUpdate: (self) => {
      self.direction === 1 ? gsap.to(disclaimer, {opacity:0, duration:0.35}) : gsap.to(disclaimer, {opacity:1, duration:0.35});
    }
  });

}, false);